
.card-hover {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 0 !important;
    box-shadow: none !important;
    overflow: hidden;
    &__bg {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
        transform-origin: center center;
        display: block;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        -webkit-transition: all ease-out 0.2s;
        -ms-transition: all ease-out 0.2s;
        transition: all ease-out 0.2s;
    }
    &__content {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 20px 20px;
        text-align: center;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .card-hover {
        &__content {
            height: 100%;
            padding: 30px 20px;
        }
    }
}
@media (min-width: 1024px) {
    .card-hover {
        &:hover {
            .card-hover {
                &__bg {
                    transform: translate(-50%, -50%) scale(1.2);
                }
            }
        }
        &__content {
            min-height: 600px;
            padding: 60px 20px;
        }
    }
}
@media (min-width: 1200px) {
}

::v-deep {
    .v-text-field .v-label {
        background: var(--v-grey-f8-base);
    }
}
